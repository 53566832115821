import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/core';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const TOS: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>Privacy Policy</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title">TOS</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <h5>TOS</h5>
                <div class="entry-content">
                  <h3>1. Terms</h3>

                  <p>
                    By accessing this website, you are agreeing to be bound by these web site Terms
                    and Conditions of Use, all applicable laws, and regulations, and agree that you
                    are responsible for compliance with any applicable local laws. If you do not
                    agree with any of these terms, you are prohibited from using or accessing this
                    site. The materials contained in this website are protected by applicable
                    copyright and trademark law.
                  </p>

                  <h3>2. Use License</h3>

                  <p>
                    Permission is granted to temporarily download one copy of the materials
                    (information or software) topbuyingguide.com website for personal,
                    non-commercial transitory viewing only. This is the grant of a license, not a
                    transfer of title, and under this license you may not:
                    <br /> modify or copy the materials;
                    <br /> use the materials for any commercial purpose, or for any public display
                    (commercial or non-commercial);
                    <br /> attempt to decompile or reverse engineer any software contained on
                    topbuyingguide.com website;
                    <br /> remove any copyright or other proprietary notations from the materials;
                    or
                    <br /> transfer the materials to another person or “mirror” the materials on any
                    other server.
                    <br /> This license shall automatically terminate if you violate any of these
                    restrictions and may be terminated by topbuyingguide.com at any time. Upon
                    terminating your viewing of these materials or upon the termination of this
                    license, you must destroy any downloaded materials in your possession whether in
                    electronic or printed format.
                  </p>

                  <h3>3. Disclaimer</h3>

                  <p>
                    The materials on topbuyingguide.com web site are provided “as is”.
                    topbuyingguide.com makes no warranties, expressed or implied, and hereby
                    disclaims and negates all other warranties, including without limitation,
                    implied warranties or conditions of merchantability, fitness for a particular
                    purpose, or non-infringement of intellectual property or other violation of
                    rights. Further, topbuyingguide does not warrant or make any representations
                    concerning the accuracy, likely results, or reliability of the use of the
                    materials on its Internet web site or otherwise relating to such materials or on
                    any sites linked to this site.
                  </p>

                  <h3>4. Limitations</h3>

                  <p>
                    In no event shall topbuyingguide or its suppliers be liable for any damages
                    (including, without limitation, damages for loss of data or profit, or due to
                    business interruption,) arising out of the use or inability to use the materials
                    on topbuyingguide’s Internet site, even if topbuyingguide or a topbuyingguide
                    authorized representative has been notified orally or in writing of the
                    possibility of such damage. Because some jurisdictions do not allow limitations
                    on implied warranties, or limitations of liability for consequential or
                    incidental damages, these limitations may not apply to you.
                  </p>

                  <h3>5. Revisions and Errata</h3>

                  <p>
                    The materials appearing on topbuyingguide&#8217;s web site could include
                    technical, typographical, or photographic errors. topbuyingguide&#8217;s does
                    not warrant that any of the materials on its web site are accurate, complete, or
                    current. topbuyingguide’s may make changes to the materials contained on its web
                    site at any time without notice. topbuyingguide does not, however, make any
                    commitment to update the materials.
                  </p>

                  <h3>6. Links</h3>

                  <p>
                    topbuyingguide has not reviewed all of the sites linked to its Internet web site
                    and is not responsible for the contents of any such linked site. The inclusion
                    of any link does not imply endorsement by topbuyingguide of the site. Use of any
                    such linked website is at the user’s own risk.
                  </p>

                  <h3>7. Site Terms of Use Modifications</h3>

                  <p>
                    topbuyingguide may revise these terms of use for its web site at any time
                    without notice. By using this website you are agreeing to be bound by the
                    then-current version of these Terms and Conditions of Use.
                  </p>

                  <h3>8. Governing Law</h3>

                  <p>
                    Any claim relating to topbuyingguide.com web site shall be governed by the laws
                    of the State of topbuyingguide.com without regard to its conflict of law
                    provisions.
                  </p>

                  <p>General Terms and Conditions applicable to Use of a Web Site.</p>
                </div>
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default TOS;
